import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import pic01 from '../assets/images/pic01.jpg'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="॥ ॐ श्री गणामहाराज ॥" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main special">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>श्री गुरुगजाननलीला ग्रंथ</h2>
                </header>
                <ul className="actions">
                  <li>
                    <Link to="/gajananleela/rruna" className="button">
                      ऋणनिर्देश
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/yethe" className="button">
                      येथे सांगावेसे वाटते
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/1" className="button">
                      अध्याय १ ला
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/2" className="button">
                      अध्याय २ रा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/3" className="button">
                      अध्याय ३ रा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/4" className="button">
                      अध्याय ४ था
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/5" className="button">
                      अध्याय ५ वा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/6" className="button">
                      अध्याय ६ वा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/7" className="button">
                      अध्याय ७ वा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/8" className="button">
                      अध्याय ८ वा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/9" className="button">
                      अध्याय ९ वा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/10" className="button">
                      अध्याय १० वा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/11" className="button">
                      अध्याय ११ वा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/12" className="button">
                      अध्याय १२ वा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/13" className="button">
                      अध्याय १३ वा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/14" className="button">
                      अध्याय १४ वा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/15" className="button">
                      अध्याय १५ वा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/16" className="button">
                      अध्याय १६ वा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/17" className="button">
                      अध्याय १७ वा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/18" className="button">
                      अध्याय १८ वा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/19" className="button">
                      अध्याय १९ वा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/20" className="button">
                      अध्याय २० वा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/21" className="button">
                      अध्याय २१ वा
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/namaskar" className="button">
                      नमस्कार
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/mantraprabhav1" className="button">
                      मंत्र प्रभाव १
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/mantraprabhav2" className="button">
                      मंत्र प्रभाव २
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/dhun" className="button">
                      धुन
                    </Link>
                  </li>
                  <li>
                    <Link to="/gajananleela/arati" className="button">
                      आरती
                    </Link>
                  </li>
                  <li>
                    <Link to="/other/stotra" className="button">
                      स्तोत्र
                    </Link>
                  </li>
                </ul>
              </div>
              <span className="image">
                <img src={pic01} alt="" />
              </span>
            </div>

          </section>

          {/* <section id="first" className="main special" style={{ 'display': 'none' }}> */}
          <section id="first" className="main special">
              <div className="spotlight">
                <div className="content">
                  <header className="major">
                    <h2>नामानुभव ग्रंथ</h2>
                  </header>
                  <ul className="actions">
                    <li>
                      <Link to="/naamaanubhava/cover" className="button">
                        मुखपृष्ठ
                    </Link>
                    </li>
                    <li>
                      <Link to="/naamaanubhava/preface" className="button">
                        नामाविषयी थोडेसे
                    </Link>
                    </li>
                    <li>
                      <Link to="/naamaanubhava/main" className="button">
                        ग्रंथ
                    </Link>
                    </li>
                    <li>
                      <Link to="/naamaanubhava/conclude" className="button">
                        पूर्णाहुती
                    </Link>
                    </li>
                  </ul>
                </div>
              </div>
            {/* <header className="major">
              <h2>Magna veroeros</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-code"></span>
                <h3>Ipsum consequat</h3>
                <p>
                  Sed lorem amet ipsum dolor et amet nullam consequat a feugiat
                  consequat tempus veroeros sed consequat.
                </p>
              </li>
              <li>
                <span className="icon major style3 fa-copy"></span>
                <h3>Amed sed feugiat</h3>
                <p>
                  Sed lorem amet ipsum dolor et amet nullam consequat a feugiat
                  consequat tempus veroeros sed consequat.
                </p>
              </li>
              <li>
                <span className="icon major style5 fa-diamond"></span>
                <h3>Dolor nullam</h3>
                <p>
                  Sed lorem amet ipsum dolor et amet nullam consequat a feugiat
                  consequat tempus veroeros sed consequat.
                </p>
              </li>
            </ul>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer> */}
          </section>

          {/* <section id="second" className="main special" style={{ 'display': 'none' }}> */}
          <section id="second" className="main special">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>इतर साहित्य</h2>
                </header>
                <ul className="actions">
                  <li>
                    <Link to="/other/dadaanswer" className="button">
                      दादा पत्रोत्तर
                    </Link>
                  </li>
                  <li>
                    <Link to="/other/paandurang" className="button">
                      पांडुरंग शास्त्री आठवले स्तवन
                    </Link>
                  </li>
                  <li>
                    <Link to="/other/dravyachi" className="button">
                      नको लालसा द्रव्याची (अभंग)
                    </Link>
                  </li>
                  <li>
                    <Link to="/other/madhavi" className="button">
                      माधवी आशीर्वाद
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <header className="major">
              <h2>Ipsum consequat</h2>
              <p>
                Donec imperdiet consequat consequat. Suspendisse feugiat congue
                <br />
                posuere. Nulla massa urna, fermentum eget quam aliquet.
              </p>
            </header>
            <ul className="statistics">
              <li className="style1">
                <span className="icon fa-code-fork"></span>
                <strong>5,120</strong> Etiam
              </li>
              <li className="style2">
                <span className="icon fa-folder-open-o"></span>
                <strong>8,192</strong> Magna
              </li>
              <li className="style3">
                <span className="icon fa-signal"></span>
                <strong>2,048</strong> Tempus
              </li>
              <li className="style4">
                <span className="icon fa-laptop"></span>
                <strong>4,096</strong> Aliquam
              </li>
              <li className="style5">
                <span className="icon fa-diamond"></span>
                <strong>1,024</strong> Nullam
              </li>
            </ul>
            <p className="content">
              Nam elementum nisl et mi a commodo porttitor. Morbi sit amet nisl
              eu arcu faucibus hendrerit vel a risus. Nam a orci mi, elementum
              ac arcu sit amet, fermentum pellentesque et purus. Integer maximus
              varius lorem, sed convallis diam accumsan sed. Etiam porttitor
              placerat sapien, sed eleifend a enim pulvinar faucibus semper quis
              ut arcu. Ut non nisl a mollis est efficitur vestibulum. Integer
              eget purus nec nulla mattis et accumsan ut magna libero. Morbi
              auctor iaculis porttitor. Sed ut magna ac risus et hendrerit
              scelerisque. Praesent eleifend lacus in lectus aliquam porta. Cras
              eu ornare dui curabitur lacinia.
            </p>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer> */}
          </section>

          <section id="cta" className="main special" style={{ 'display': 'none' }}>
            <header className="major">
              <h2>Congue imperdiet</h2>
              <p>
                Donec imperdiet consequat consequat. Suspendisse feugiat congue
                <br />
                posuere. Nulla massa urna, fermentum eget quam aliquet.
              </p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button special">
                    Get Started
                  </Link>
                </li>
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
