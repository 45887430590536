import React from 'react'

// import logo from '../assets/images/logo.svg';
// import logo from '../assets/images/ganamaharaj.jpg';

const Header = (props) => (
    <header id="header" className="alt">
        {/* <span className="logo"><img src={logo} alt="" style={{'width': '30%'}}/></span> */}
        <span className="logo">॥ ॐ ॥</span>
        <h1>॥ श्री गणामहाराज ॥</h1>
        <p>श्री गजानन महाराज (शेगांव) यांचे परम भक्त</p>
    </header>
)

export default Header
